import BaseBean from "@/utils/BaseBean";

export interface IPiChangeCardDataObj {
    utilInst:PiChangeCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class PiChangeCardUtil extends BaseBean{
    public dataObj:IPiChangeCardDataObj;

    constructor(proxy:any,dataObj:IPiChangeCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        this.dataObj.otherParams.piData=await this.utils.OrderProviderApi.getAllPubPi({});
        //会先执行beforeOpen方法，然后执行这里。这个时候form.piId就有值了，但是为了满足下拉的数据适配，需要在这里处理一下form.piId
        this.dataObj.form.piId=this.dataObj.otherParams.piData.find((item:any)=>item.value==this.dataObj.form.piId);
    }
    //检查保存的数据是否合法
    public checkSaveData():boolean{
        let orderData=this.dataObj.refMap.get('order').otherParams.orderData;
        //提醒人名币为0的项
        let existZeroPrice=false;
        orderData.cfgData.forEach((item1:any)=>{
            item1.itemChildren.forEach((item2:any)=>{
                if(!item2.header.price){
                    existZeroPrice=true;
                    this.utils.Tools.warning({message:'【'+item2.header.cxName+'】 存在单价为0项'});
                    return;
                }
            })
        })
        if(existZeroPrice){
            this.dataObj.otherParams.activeName='orderInfo';
            return false;
        }
        this.dataObj.form.jsonContent=orderData;
        if(this.dataObj.form.piId)this.dataObj.form.piId=this.dataObj.form.piId.value;//这个是对象，所以存的时候要取值来存储。
        return true;
    }

    //提交审批
    public async submit():Promise<void>{
        this.utils.Tools.configBox({
            message:'确定提交吗?',
            sureFn:async ()=> {
                let res=await this.utils.OrderProviderApi.piChangeSubmit({params:{id:this.dataObj.form.id}});
                if(res.result){
                    this.utils.Tools.success({message:'操作成功'});
                    await this.proxy.engine.engineUtil.doAddOrLoad(this.dataObj.form.id,'card');
                }
            }
        });
    }
    //审核[通过、退回]确定事件
    public async doProcess():Promise<any>{
        let res=await this.utils.OrderProviderApi.processPiChange({
            params:{
                status:this.dataObj.otherParams.processInfo.status,
                desc:this.dataObj.otherParams.processInfo.desc,
                id:this.dataObj.form.id
            }
        });
        if(res.result){
            this.utils.Tools.success();
            this.dataObj.otherParams.processInfo.visible=false;
            this.proxy.engine.engineParams.ownerComp.pageListRef.queryHandler(true)
            this.proxy.dialogInst.dialogVisible=false;
        }
    }
    public openShDialog(status:number):void{
        this.dataObj.otherParams.processInfo.status=status;
        this.dataObj.otherParams.processInfo.visible=true;
    }
    //格式化PI审核历史数据
    public formatShData(res:any):void{
        this.dataObj.otherParams.shTbData=res.data.piChangeProcessBeanList.map((item:any)=>{
            switch (item.type) {
                case 0:item.type='大区经理';break;
                case 1:item.type='财务经理';break;
            }
            switch (item.status) {
                case 0:item.status='通过';break;
                case 1:item.status='退回';break;
            }
            return item;
        });
    }
}