import BaseBean from "@/utils/BaseBean";

export interface IDqjlListDataObj {
    utilInst:DqjlListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}

export default class DqjlListUtil extends BaseBean{
    public dataObj:IDqjlListDataObj

    constructor(proxy:any,dataObj:IDqjlListDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
    }
}