import PiChangeCard from '../piChange/PiChangeCard.vue';
import {reactive, toRefs,defineComponent,onBeforeMount, onMounted, getCurrentInstance} from 'vue';
import DqjlListUtil ,{IDqjlListDataObj} from './dqjlListUtil';
export default defineComponent ({
    name: 'DqjlList',
    setup(){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IDqjlListDataObj=reactive<IDqjlListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                queryParam: {
                    cardFrom:'DqjlList'
                },
                cardFrom:'DqjlList',
                gridTitle:'大区经理审核',
                isShowFixCol:false,
                paramHeight:54,//tab的高度
                modelComp: PiChangeCard,
                modelMethod: utils.OrderProviderApi.buildUrl('/piChange/pageData')
            },
            otherParams:{
                activeName:'all',
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new DqjlListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            dataObj.pageList.queryParam.queryType='all';
        })


        const formatPageInfo=(options:any)=>{
            return options;
        }
        //tab切换事件
        const handleChange=(tabName:string)=>{
            dataObj.pageList.queryParam.queryType=tabName;
            dataObj.pageListRef.queryHandler(true);
        }
        //查看pi弹出框
        const piDetail=async (piId:string)=>{
            await utils.UtilPub.openDialog({proxy:proxy,addOrEdit:'edit',id:piId,modelComp:PiChangeCard,ownerComp:proxy,cardFrom:'DqjlList',
                title:'PI变更详情',contentHeight:'',overflow:'hidden',showFooterBtn:false,fullscreen:true})
        }
        return{
            ...toRefs(dataObj),formatPageInfo,handleChange,piDetail
        }
    }
});